import { useDisclosure } from "@chakra-ui/react"
import { EntityId, FulfillmentTypes } from "@jackfruit/common"
import React, { MouseEvent, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import FulfillmentUpdateConfirmationDialog from "~/components/blocks/FulfillmentUpdateConfirmationDialog"
import { usePage } from "~/hooks/usePage"
import { usePageCart } from "~/hooks/usePageCart"
import { usePageSession } from "~/hooks/usePageSession"
import { actions } from "~/redux/process"
import { interactionResponse } from "~/services/webvitals/defer"
import { parseArgsFromEvent, registerGlobalFunction } from "../register"

/*
Usage example:

<button 
  data-product-id=2519
  data-default-fulfillment="delivery"
  onClick='api.addProductToCart(event)'
>
    Get Started
</button>
*/

interface FuncArgs {
  blockId: number
  productId: number
  productTemplateId: number
  blockTemplateId: number
  defaultFulfillment: FulfillmentTypes
}

interface Props {}

const FuncAddProductToCart: React.FC<Props> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    pageSession: { isReady },
  } = usePageSession()
  const {
    page: {
      isPickupEnabled,
      isDeliveryEnabled,
      defaultFulfillment: defaultFulfillmentFromState,
    },
  } = usePage()
  const {
    pageCart: { fulfillment: currentFulfillment },
  } = usePageCart()

  const { isOpen, onOpen: openDialog, onClose: closeDialog } = useDisclosure()
  const [lineItemsToKeep, setLineItemsToKeep] = useState<EntityId[]>([])
  const [lineItemsToRemove, setLineItemsToRemove] = useState<EntityId[]>([])

  const onDialogDecisionConfirm = useCallback(() => {
    dispatch(actions.productCompatibilityDialog({ choice: true }))
  }, [dispatch])

  const onDialogDecisionCancel = useCallback(() => {
    dispatch(actions.productCompatibilityDialog({ choice: false }))
  }, [dispatch])

  const onClose = useCallback(() => {
    closeDialog()
    setLineItemsToKeep([])
    setLineItemsToRemove([])
  }, [closeDialog])

  const onOpen = useCallback(
    (data: { lineItemsToKeep: EntityId[]; lineItemsToRemove: EntityId[] }) => {
      setLineItemsToKeep(data.lineItemsToKeep)
      setLineItemsToRemove(data.lineItemsToRemove)
      openDialog()
    },
    [openDialog]
  )

  const isFulfillmentValid = useCallback(
    (fulfillment: FulfillmentTypes) => {
      if (fulfillment === "pickup") {
        return isPickupEnabled
      }

      if (fulfillment === "delivery") {
        return isDeliveryEnabled
      }

      return false
    },
    [isDeliveryEnabled, isPickupEnabled]
  )

  const addProductToCart = useCallback(
    async (event: MouseEvent<HTMLButtonElement, MouseEvent>) => {
      await interactionResponse()
      
      const { productId, defaultFulfillment: defaultFulfillmentFromButton } =
        parseArgsFromEvent<FuncArgs>(event)

      const fulfillment = isFulfillmentValid(defaultFulfillmentFromButton)
        ? defaultFulfillmentFromButton
        : defaultFulfillmentFromState

      if (isReady) {
        dispatch(
          actions.createLineItemFromButton({
            currentFulfillment,
            fulfillment,
            productId,
            onClose,
            onOpen,
          })
        )
      }
    },
    [
      currentFulfillment,
      defaultFulfillmentFromState,
      dispatch,
      isFulfillmentValid,
      isReady,
      onClose,
      onOpen,
    ]
  )

  useEffect(() => {
    if (window) {
      registerGlobalFunction("addProductToCart", addProductToCart)
    }
  }, [addProductToCart])

  return (
    <FulfillmentUpdateConfirmationDialog
      title={t(
        "components.api.functions.FunctionAddProductToCart.UpdateConfirmationDialog.title"
      )}
      description={t(
        "components.api.functions.FunctionAddProductToCart.UpdateConfirmationDialog.description"
      )}
      lineItemsToKeep={lineItemsToKeep}
      lineItemsToRemove={lineItemsToRemove}
      onClose={onDialogDecisionCancel}
      onConfirm={onDialogDecisionConfirm}
      isOpen={isOpen}
    />
  )
}

export default FuncAddProductToCart
