import { Box, Heading, Stack, useDisclosure } from "@chakra-ui/react"
import {
  allFulfillments,
  BlockFulfillmentEntityHydrated,
  EntityId,
  FulfillmentTypes,
} from "@jackfruit/common"
import React, { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useCart } from "~/hooks/useCart"
import { useCurrentPageId } from "~/hooks/useCurrentPageId"
import { useLineItems } from "~/hooks/useLineItems"
import { usePageSession } from "~/hooks/usePageSession"
import { actions } from "~/redux/process"
import { interactionResponse } from "~/services/webvitals/defer"
import CardButton from "../fulfillment/CardButton"
import ScrollLinkWrapper from "../scroll-wrappers/ScrollLinkWrapper"
import Wrapper from "../Wrapper"
import FulfillmentUpdateConfirmationDialog from "./FulfillmentUpdateConfirmationDialog"

interface Props {
  config: BlockFulfillmentEntityHydrated
}

const FulfillmentBlock: React.FC<Props> = ({ config }) => {
  const { t } = useTranslation()
  const {
    mainTitle,
    fulfillmentStyle,
    containerBgColor,
    fontColor,
    checkBgColor,
  } = config
  const pageId = useCurrentPageId()
  const { pageSession } = usePageSession()

  const flow = pageSession.pageFlow

  const fulfillmentConfig = useMemo(
    () => ({
      delivery: {
        title: config.deliveryTitle,
        desc: config.deliveryDescription,
        icon: config.deliverySvgIcon,
      },
      pickup: {
        title: config.pickupTitle,
        desc: config.pickupDescription,
        icon: config.pickupSvgIcon,
      },
    }),
    [
      config.deliveryDescription,
      config.deliverySvgIcon,
      config.deliveryTitle,
      config.pickupDescription,
      config.pickupSvgIcon,
      config.pickupTitle,
    ]
  )

  const { cart } = useCart()
  const { lineItems } = useLineItems(cart.lineItemIds)
  const dispatch = useDispatch()
  const currentFulfillment = cart.fulfillment
  const { isOpen, onOpen: openDialog, onClose: closeDialog } = useDisclosure()
  const [lineItemsToKeep, setLineItemsToKeep] = useState<EntityId[]>([])
  const [lineItemsToRemove, setLineItemsToRemove] = useState<EntityId[]>([])
  const [targetFulfillment, setTargetFulfillment] = useState(
    fulfillmentConfig.delivery
  )

  const onClose = useCallback(() => {
    closeDialog()
    setLineItemsToKeep([])
    setLineItemsToRemove([])
  }, [closeDialog])

  const onOpen = useCallback(
    (data: { lineItemsToKeep: EntityId[]; lineItemsToRemove: EntityId[] }) => {
      setLineItemsToKeep(data.lineItemsToKeep)
      setLineItemsToRemove(data.lineItemsToRemove)
      openDialog()
    },
    [openDialog]
  )

  const onChangeFulfillment = useCallback(
    async (choice: FulfillmentTypes) => {
      if (choice !== currentFulfillment) {
        setTargetFulfillment(fulfillmentConfig[choice])
        await interactionResponse()
        dispatch(
          actions.updateFulfillment({
            pageId,
            onOpen,
            onClose,
            fulfillment: choice,
          })
        )
      }
    },
    [currentFulfillment, dispatch, fulfillmentConfig, onClose, onOpen, pageId]
  )

  const onDialogDecisionConfirm = useCallback(() => {
    dispatch(actions.productCompatibilityDialog({ choice: true }))
  }, [dispatch])

  const onDialogDecisionCancel = useCallback(() => {
    dispatch(actions.productCompatibilityDialog({ choice: false }))
  }, [dispatch])

  // disable the fulfillment selection in these scenarios
  // if at least one picture is still getting uploaded
  // as we are not yet able to decide for the product match
  // only scenario where this does not applies is:
  // store first with no store selected yet
  const someLineItemsAreNotReady = lineItems.some(lineItem => !lineItem.isReady)
  const isStoreFirstAndHasNoStore =
    flow === "store-first" &&
    !Boolean(cart.storeId) &&
    currentFulfillment === "pickup"

  const isDisabled = someLineItemsAreNotReady && !isStoreFirstAndHasNoStore
  const isBoxed = fulfillmentStyle === "Boxed"
  const boxedLayout = isBoxed
    ? {
        maxW: "90rem",
        mx: "auto",
      }
    : {}

  return (
    <Box {...boxedLayout}>
      <Wrapper
        background={containerBgColor}
        color={fontColor}
        borderRadius={isBoxed ? { md: "xl" } : ""}
      >
        <Box textAlign="center" paddingTop={8} paddingBottom={9} mx="auto">
          <Heading
            as="h3"
            fontSize="2xl"
            mb={8}
            color="white"
            textTransform="capitalize"
          >
            {mainTitle}
          </Heading>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={8}
            align={{ base: "center", md: "flex-start" }}
            justify="space-between"
          >
            {allFulfillments.map((fulfillmentType, index) => {
              const {
                [fulfillmentType]: { title, desc, icon },
              } = fulfillmentConfig

              return (
                <Box flexBasis={0} flexGrow={1} key={index}>
                  <ScrollLinkWrapper
                    toType="nextAction"
                    currentBlock="fulfillment"
                    offset={0}
                    isDisabled={isDisabled}
                  >
                    <CardButton
                      isDisabled={isDisabled}
                      index={index}
                      title={title}
                      description={desc}
                      svgIcon={icon}
                      collectChoice={currentFulfillment === "pickup" ? 0 : 1}
                      containerBgColor={containerBgColor}
                      fontColor={fontColor}
                      checkBgColor={checkBgColor}
                      onSetChoice={onChangeFulfillment}
                      fulfillmentType={fulfillmentType}
                    />
                  </ScrollLinkWrapper>
                </Box>
              )
            })}
          </Stack>
        </Box>

        <FulfillmentUpdateConfirmationDialog
          title={t(
            "components.blocks.FulfillmentBlock.UpdateConfirmationDialog.title",
            { title: targetFulfillment.title }
          )}
          description={t(
            "components.blocks.FulfillmentBlock.UpdateConfirmationDialog.description",
            { title: targetFulfillment.title }
          )}
          lineItemsToKeep={lineItemsToKeep}
          lineItemsToRemove={lineItemsToRemove}
          onClose={onDialogDecisionCancel}
          onConfirm={onDialogDecisionConfirm}
          isOpen={isOpen}
        />
      </Wrapper>
    </Box>
  )
}

export default React.memo(FulfillmentBlock)
