import { AppEntityHydrated, PageEntityHydrated } from "@jackfruit/common"
import { ErrorBoundary } from "@sentry/gatsby"
import { orderBy } from "lodash"
import React from "react"
import GlobalFunctions from "~/components/api/GlobalFunctions"
import ErrorFallback from "~/components/ErrorFallback"
import PageBlock from "~/components/PageBlock"
import Head from "../Head"

interface Props {
  pageContext: {
    config: AppEntityHydrated
    page: PageEntityHydrated
    seo: {
      canonical: string
    }
  }
}

const PageBlockLayout: React.FC<Props> = ({ pageContext }) => {
  const { config, page, seo } = pageContext
  const { blocks, pageSeo, lambdaAbExperimentId } = page

  const sortedBlocks = orderBy(blocks, ["order"], ["asc"])

  return (
    <>
      <Head
        config={config}
        lambdaAbExperimentId={lambdaAbExperimentId}
        seo={{
          ...seo,
          title: pageSeo?.openGraphTitle,
          description: pageSeo?.openGraphDescription,
          featureImage: pageSeo?.openGraphFeatureImage,
        }}
      />

      {sortedBlocks.map(block => {
        return (
          <ErrorBoundary
            key={`error-boundary-${block.id}`}
            beforeCapture={scope => {
              scope.setTag("block", block.type)
            }}
            fallback={ErrorFallback}
            showDialog={true}
          >
            <PageBlock
              key={block.id}
              block={block}
              config={config}
              page={page}
            />
          </ErrorBoundary>
        )
      })}
      <GlobalFunctions />
    </>
  )
}

export default PageBlockLayout
